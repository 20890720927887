import React from 'react';
import {Layout} from "./partials/Layout";
import Table from "./partials/Table";


const titles=[
    {
        label:'',
        key:'index'
    },
    {
        label:'',
        key:'1'
    },
    {
        label:'',
        key:'2'
    },
]


const stakeholders=[
    {
        name:'राज्य सरकार',
        count:'1',
        amount:'12,09'
    },
    {
        name:'ग्राम सेवा सह. समिति',
        count:'144',
        amount:'2,84'
    },
    {
        name:'प्राथ. सह. उप. भंडार',
        count:'43',
        amount:'0,82'
    },
    {
        name:'व्यक्तिगत',
        count:'13263',
        amount:'18,29'
    },
    {
        name:'क्रय विक्रय सहकारी समितियाॅ',
        count:'2',
        amount:'00,02'
    },
    {
        name:'योग',
        count:'13453',
        amount:'34,06'
    },
]

const businessCondition =[
    {
        1:'2017-18',
        2:'0',
        3:'0',
        4:'2725',
        5:'2088,2',
        6:'2725',
        7:'2088,20',
        8:'76,63',
        9:'-31,44'
    },
    {
        1:'2018-19',
        2:'0',
        3:'0',
        4:'2556',
        5:'2137,73',
        6:'2556',
        7:'2137,73',
        8:'83,64',
        9:'7,42'
    },
    {
        1:'2019-20',
        2:'0',
        3:'0',
        4:'2680',
        5:'2054,50',
        6:'2680',
        7:'2054,50',
        8:'76,66',
        9:'5,09'
    },
    {
        1:'2020-21',
        2:'0',
        3:'0',
        4:'2714',
        5:'1648,73',
        6:'2714',
        7:'1648,73',
        8:'60,75',
        9:'-'
    },
    {
        1:'2021-22 (मई 2021 तक)',
        2:'0',
        3:'0',
        4:'0',
        5:'234,19',
        6:'0',
        7:'234,19',
        8:'0',
        9:'-'
    }
]

const data =[
    {
        1:'नाम संस्था',
        2:'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        1:'पता',
        2:'विजय सहकार भवन, श्री गंगानगर रोड़, बीकानेर'
    },
    {
        1:'पंजीयन संख्या',
        2:'373/एफ दिनांक 23.1.1963'
    },
    {
        1:'कार्य क्षेत्र',
        2:'बीकानेर जिला'
    },
    {
        index: (<div  style={{
            paddingTop:'15px'
        }}>5</div>),
        1: <div style={{
            paddingTop:'15px'
        }}>संस्था द्वारा संचालित विक्रय केन्द्र</div>,
        2:(
            <table className={'w-100'}>
                <tr>
                    <td>उपहार सुपर मार्केट/सुपर मार्केट</td>
                    <td>2</td>
                </tr>
                <tr>
                    <td>रिटेल कपड़ा शोप</td>
                    <td>1</td>
                </tr>
                <tr>
                    <td>मेडिकल शोप/आयुर्वेदिक शोप</td>
                    <td>7</td>
                </tr>
                <tr>
                    <td>गैस शोरुम (इंडेन गैस एजेन्सी)</td>
                    <td>1</td>
                </tr>
            </table>
           ),
        verticalAlign:'top'
    },
    // {
    //     index: (<div  style={{
    //         // paddingTop:'15px'
    //     }}>6</div>),
    //     1: <div style={{
    //         // paddingTop:'15px'
    //     }}>सदस्यता एवं हिस्सा पूंजी स्थिति निम्न प्रकार है</div>,
    //     2: (
    //         <div>
    //             <h5 className={'text-center'}>भंडार में कुल हिस्सा पूंजी 34.06 लाख रुपये है।</h5>
    //             <table className={'w-100'}>
    //                 <tr>
    //                     <th >क्र.</th>
    //                     <th >विवरण</th>
    //                     <th >सदस्यता</th>
    //                     <th >हिस्सा पूंजी(राशि लाखो में)</th>
    //                 </tr>
    //                 {stakeholders.map((seller,index)=>(
    //                     <tr>
    //                         <td>{index+1}</td>
    //                         <td>{seller.name}</td>
    //                         <td>{seller.count}</td>
    //                         <td>{seller.amount}</td>
    //                     </tr>
    //                 ))}
    //             </table>
    //         </div>
    //     ),
    //     verticalAlign:'top'
    // },
    // {
    //     index: (<div  style={{
    //         paddingTop:'15px'
    //     }}>7</div>),
    //     1: <div style={{
    //         paddingTop:'15px'
    //     }}>व्यापारिक स्थिति</div>,
    //     2: (
    //         <div>
    //             <table className={'w-100'}>
    //                 <tr>
    //                     <th>वर्ष</th>
    //                     <th>लक्ष्य नि.</th>
    //                     <th>पूर्ति नि.</th>
    //                     <th>लक्ष्य अनि.</th>
    //                     <th>पूर्ति अनि.</th>
    //                     <th>लक्ष्य नि. व अनि.</th>
    //                     <th>पूर्ति योग नि. व अनि.</th>
    //                     <th>पूर्ति प्रतिशत</th>
    //                     <th>वर्ष में लाभ हानि</th>
    //                 </tr>
    //                 {businessCondition.map((condition)=>(
    //                     <tr>
    //                         <td>{condition[1]}</td>
    //                         <td>{condition[2]}</td>
    //                         <td>{condition[3]}</td>
    //                         <td>{condition[4]}</td>
    //                         <td>{condition[5]}</td>
    //                         <td>{condition[6]}</td>
    //                         <td>{condition[7]}</td>
    //                         <td>{condition[8]}</td>
    //                         <td>{condition[9]}</td>
    //                     </tr>
    //                 ))}
    //             </table>
    //         </div>
    //     ),
    //     verticalAlign:'top'
    // }
]

function AboutUs(props) {
    return (
        <Layout>
            <Table header={false} titles={titles} heading={'About Us'} data={data}/>
        </Layout>
    );
}

export default AboutUs;