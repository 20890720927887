import React from 'react';
import Marquee from "./Marquee";
function News(props) {
    return (
        <div
            className=" m-5 card"
        >
            <div className="card-header">
                <h4 className="card-title m-0 p-0 text-center" style={{fontWeight:'bold',fontSize:'large'}}>
                    उपहार कूपन योजनाएं
                </h4>
            </div>
            <div className="card-body"  >
                {/* <marquee behavior="scroll" scrollamount="4" direction="up" style={{
                    height:'20rem',
                   
                }}
                onMouseOver="this.stop();"
                onMouseOut="this.start();"
                >
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                        भंडार के 500/- के सदस्य बनने पर 50/- का उपहार गिफ्ट कूपन तथा सदस्य बनाने वाले को भी 50/- का कूपन दिया जायेगा|
                    </div>
                        <hr/>
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                        यदि 2000/- के सदस्य बनते ही उसको प्रति 1000/- की खरीद पर 50/- का गिफ्ट कूपन दिया जायेगा।

                    </div>
                        <hr/>
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                        2000/- रु से अधिक की खरीद पर होम डिलेवरी फ्री दी जायेगी।

                    </div>
                        <hr/>
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                        नये गैस कनेक्शन पर 100/- का गिफ्ट कूपन दिया जायेगा।
                    </div>
                    <hr/>
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                    भंडार द्वारा क्लॉथ रिटेल शॉप केईएम रोड पर रयमोंड्स, विएक्स एल, ओसीएम के कपड़ो पर 500 /- के सदस्यों को 15 प्रतिशत की छूट दी जा रही है। 
                    </div>                    
                </marquee> */}
                {/* <Marquee string="This is the string">
                <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                        भंडार के 500/- के सदस्य बनने पर 50/- का उपहार गिफ्ट कूपन तथा सदस्य बनाने वाले को भी 50/- का कूपन दिया जायेगा|
                    </div>
                        <hr/>
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                        यदि 2000/- के सदस्य बनते ही उसको प्रति 1000/- की खरीद पर 50/- का गिफ्ट कूपन दिया जायेगा।

                    </div>
                        <hr/>
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                        2000/- रु से अधिक की खरीद पर होम डिलेवरी फ्री दी जायेगी।

                    </div>
                        <hr/>
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                        नये गैस कनेक्शन पर 100/- का गिफ्ट कूपन दिया जायेगा।
                    </div>
                    <hr/>
                    <div className='p-2' style={{backgroundColor:'orange',color:'white',fontWeight:'bold',fontSize:'large'}}>
                    भंडार द्वारा क्लॉथ रिटेल शॉप केईएम रोड पर रयमोंड्स, विएक्स एल, ओसीएम के कपड़ो पर 500 /- के सदस्यों को 15 प्रतिशत की छूट दी जा रही है। 
                    </div> 


                </Marquee> */}
            </div>



        </div>
    );
}

export default News;