import React from 'react';
import './table.css'


function Table({heading,titles,data,header=true,caption}) {
    return (
<div className="mb-5">
    {(heading || caption) &&(
        <div className="text-center">
            <div className="mb-4 d-inline-block py-2 px-3" style={{
                backgroundColor:'#ff6633',
                color:'white'
            }}>
                <h4 className={"m-0 p-0"} style={{
                    color:'white'
                }}>{heading}</h4>
                {caption && (
                    <h6 className={'m-0 mt-2'} style={{
                        color:'white'
                    }}>{caption}</h6>
                )}
            </div>
        </div>
    )}

    <div className="table-wrap">
        <table className="table table-sm table-responsive-sm">
            {header &&(  <thead>
            <tr>
                {titles.map(title=>( <th>{title.label}</th>))}
            </tr>
            </thead>) }

            <tbody>
            {data.map((employee,index)=>(
                <tr className="alert" role="alert">
                    {titles.map(title=>(
                        title.key === 'index' && !employee.index ?(<td  style={{verticalAlign: employee.verticalAlign ?employee.verticalAlign :'center' }}>{index+1}</td>
                        ) :(
                    <td style={{verticalAlign: employee.verticalAlign ?employee.verticalAlign :'center' }}>{employee[title.key]}</td>
                        )
                    ))}
                </tr>
            ))}

            </tbody>
        </table>
    </div>
</div>
    )
}

export default Table;