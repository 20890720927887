import React from 'react';
import {Layout} from "./partials/Layout";
import Table from "./partials/Table";
import _imgInfo from "../../img/bsuwb/img_info.jpeg";
import _pdfInfo from "../../img/bsuwb/xl_info.xlsx";
//import _pdfInfo1 from "../../img/bsuwb/pdf_info1.pdf";
//import _xlsInfo from "../../img/bsuwb/xlsx_info.xlsx";
//import _gifNewInfo from "../../img/bsuwb/gif_newInfo.gif";
import _pdfInfo2 from "../../img/bsuwb/pdf_info2.pdf";
//import _pdfInfo3 from "../../img/bsuwb/pdf_info3.pdf";
//import _pdfInfo4 from "../../img/bsuwb/pdf_info4.pdf";
//import _pdfInfo5 from "../../img/bsuwb/pdf_info5.pdf";



const employees=[
    {
        name:'श्री चैन सिंह',
        position:'गैस गोदाम प्रभारी',
        mobile:'9414452166'
    },

    {
        name:'श्री राजेन्द्र सिंह',
        position:'प्रभारी गैस शाखा',
        mobile:'9660009294'
    },
    {
        name:'श्री आनन्द शर्मा',
        position:'मेडिकल विक्रेता शोप नं. 20 पीबीएम कैम्पस,पेंशनर्स तथा आयुर्वेदिक शोप',
        mobile:'9460227345'
    },
    {
        name:'श्री अजय गुप्ता',
        position:'मेडिकल विक्रेता शोप नं. 14 पीबीएम कैम्पस,पेंशनर्स शोप',
        mobile:'9461036310'
    },
    {
        name:'श्री राजेन्द्र औझा',
        position:'मेडिकल स्टोरकीपर प्रभारी',
        mobile:'9828451303'
    },
    {
        name:'श्री अनिल गुप्ता',
        position:'मेडिकल विक्रेता शोप नं. 6 पीबीएम कैम्पस',
        mobile:'9414642735'
    },
    {
        name:'श्री भवानी सिंह',
        position:'गैस मैकेनिक का कार्य',
        mobile:'9414264541'
    },
    {
        name:'श्री पवन शर्मा',
        position:'विक्रेता उपहार सुपर मार्केट जेएनवी कॉलोनी',
        mobile:'9252570777'
    },
    {
        name:'श्री दलपत सिंह',
        position:'विक्रेता उपहार सुपर मार्केट केईएम रोड़',
        mobile:'8559963035'
    },
    {
        name:'श्री मुकेश श्रीमाली',
        position:'मेडिकल विक्रेता शोप नं. 11 सैटेलाईट अस्पताल, पेंशनर्स तथा आयुर्वेदिक शोप',
        mobile:'9214800204'
    },
    {
        name:'श्री वसीम अकरम',
        position:'मेडिकल विक्रेता शोप नं. 9 अणचाबाई डिस्पेंसरी, पेंशनर्स शोप',
        mobile:'9929469288'
    },
    {
        name:'श्री हरिकिशन शर्मा',
        position:'मेडिकल विक्रेता शोप नं. 7 पीबीएम कैम्पस,',
        mobile:'9269282814'
    },
    {
        name:'श्री शाहजार मिर्जा',
        position:'मेडिकल विक्रेता शोप नं. 17 नोखा',
        mobile:'9667633010'
    },
    {
        name:'श्री पृथ्वी सिंह',
        position:'रिटेल कपड़ा शोप, केईएम रोड़',
        mobile:'9460779517'
    },
]


const titles=[
    {
        label:'क्र.',
        key:'index'
    },
    {
        label:'नाम कर्मचारी',
        key:'name'
    },
    {
        label:'वर्तमान में कार्यरत',
        key:'position'
    },
    {
        label:'मोबाईल नं.',
        key:'mobile'
    },
]

function Home(props) {
    return (
        <Layout>
            <div className="P-3" style={{
                position:"relative"
            }} >                
                {
                <a href={_pdfInfo} target="_blank" 
                    rel="noreferrer">
                    <strong><b>व्यक्तिगत सदस्य सूची (नए उपनियम के अनुसार)</b></strong>&nbsp; &nbsp; &nbsp; &nbsp;
                </a> 
                /*<a href={_pdfInfo3} target="_blank" 
                    rel="noreferrer">
                    <strong><b>आदेश</b></strong>&nbsp; &nbsp; &nbsp; &nbsp;
                </a>
                <a href={_pdfInfo4} target="_blank" 
                    rel="noreferrer">
                    <strong><b>निर्वाचन निर्देशिका</b></strong>&nbsp; &nbsp; &nbsp; &nbsp;
                </a> */}
                {/* <a href={_pdfInfo} target="_blank" 
                    rel="noreferrer">
                    <strong><b>व्यक्तिगत सदस्य व्यवसाय सूची</b></strong>&nbsp; &nbsp; &nbsp; &nbsp;
                </a>
                <a href={_pdfInfo5} target="_blank" 
                    rel="noreferrer">
                    <strong><b>व्यक्तिगत सदस्य व्यवसाय सूची 1</b></strong>&nbsp; &nbsp; &nbsp; &nbsp;
                </a> */}
                <br></br>
                {/* <img width="80px" src={_gifNewInfo} style={{                    
                    position:'absolute',
                    top:"-15px",
                    left:"-15px"
                }}/> */}

                {/* <a href={_xlsInfo} target="_blank" > */}
                
            </div>
            <br/>

            <Table data={employees} heading={'बीकानेर भंडार के काउंटरो पर कार्यरत कार्मिको की सूची'} titles={titles}/>
            {/* <Table data={employees2} heading={'अनुबन्धित कर्मचारी'} titles={titles}/> */}
        </Layout>
    );
}

export default Home;