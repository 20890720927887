import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
/*------ Pages-----*/
import  Home  from "./Pages/bsuwb/Home";
import ContactUs from "./Pages/bsuwb/ContactUs";
import NotFound from "./Pages/bsuwb/404";
import ScrollToTopRoute from "./ScrollToTopRoute";
import OurTurnover from "./Pages/bsuwb/OurTurnover";
import BoardOfDirectors from "./Pages/bsuwb/BoardOfDirectors";
import OurOffice from "./Pages/bsuwb/OurOffice";
import OurShops from "./Pages/bsuwb/OurShops";
import OurLocation from "./Pages/bsuwb/OurLocation";
import AboutUs from "./Pages/bsuwb/AboutUs";
import OurWork from "./Pages/bsuwb/OurWork";

class App extends Component {
  componentDidMount() {
    setTimeout(()=> this.props.hideLoader(),4000)
  }
  render() {
    return (
      <Router>
        <Switch>
          <ScrollToTopRoute exact={true} path={"/"} component={Home} />
          <ScrollToTopRoute exact={true} path={"/contact-us"} component={ContactUs} />
          <ScrollToTopRoute exact={true} path={"/our-turnover"} component={OurTurnover} />
          <ScrollToTopRoute exact={true} path={"/board-of-directors"} component={BoardOfDirectors} />
          <ScrollToTopRoute exact={true} path={"/our-office"} component={OurOffice} />
          <ScrollToTopRoute exact={true} path={"/our-shops"} component={OurShops} />
          <ScrollToTopRoute exact={true} path={"/our-location"} component={OurLocation} />
          <ScrollToTopRoute exact={true} path={"/about-us"} component={AboutUs} />
          <ScrollToTopRoute exact={true} path={"/our-work"} component={OurWork} />

          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
