import React from 'react';

function Footer({FooterData}) {
    return (
        <div className="footer_bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-5 col-sm-6">
                        <p className="mb-0 f_400">All Rights Reserved by Shree Pankh Soft.</p>

                        <a href="https://www.hitwebcounter.com" target="_blank">
                        <img src="https://hitwebcounter.com/counter/counter.php?page=7866641&style=0019&nbdigits=6&type=page&initCount=0" title="Free Counter" Alt="web counter"   border="0" /></a>

                    </div>
                    <div className="col-lg-4 col-md-3 col-sm-6">
                        <div className="f_social_icon_two text-center">
                            {
                                FooterData.socialIcon.map(item =>{
                                    return(
                                        <a href="/" key={item.id}><i className={item.icon}></i></a>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <ul className="list-unstyled f_menu text-right">
                            <li><a href=".#">Terms of Use</a></li>
                            <li><a href=".#">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;