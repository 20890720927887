import React from 'react';

function Advert(props) {
    return (
        <div className='w-100 px-5 py-1' style={{
            background:'#ff6633',
            display:'flex',
            alignItems:'center',
            color:'white'
        }}>
            <marquee behavior="" direction="">
                🔥🔥🔥 PANKH SOFTWARE : इन्वेंटरी / एकाउंटिंग GST का कम्पलीट सॉफ्टवेयर सोल्युशन, आकर्षक वेब साइट्स के लिए हमसे संपर्क करे (+91-9828577159) 🔥🔥🔥
            </marquee>
        </div>
    );
}

export default Advert;
