import React, {Component} from 'react';
import EventTeamItem from './ServiceSliderItem';
import Slider from 'react-slick';
import IndaneImg from "../../../img/bsuwb/indane_img.png"
import ClothImg from "../../../img/bsuwb/cloth.jpg"
import MedicineImg from "../../../img/bsuwb/medicine_crop.jpg"
import SuperMarketImg from "../../../img/bsuwb/supermarket_crop.jpg"
import OldAgeImg from "../../../img/bsuwb/old_age_crop.jpg"

class ServicesSlider extends Component{
    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 4,
            slidesToScroll: 1,
        };
        return(
            <section className="event_team_area py-5">
                <div className="container">
                    <div className="hosting_title security_title text-center">
                        <h2 className="wow fadeInUp" data-wow-delay="0.2s">Our Services</h2>
                    </div>
                    <Slider ref={c => (this.slider = c)} className="event_team_slider" {...settings}>
                        <EventTeamItem image={IndaneImg} name="गैस सिलेण्डर सप्लाई का कार्य" post=""/>
                        <EventTeamItem image={ClothImg} name="कपड़े का व्यापार" post=""/>
                        <EventTeamItem image={MedicineImg} name="मेडिसिन का व्यापार" post=""/>
                        <EventTeamItem image={SuperMarketImg} name="जनरल खाद्यय सामग्री का व्यापार" post=""/>
                        <EventTeamItem image={OldAgeImg} name="पेंशनर्स को जैनरिक दवाईयाॅॅ उपलब्ध करवाना" post=""/>
                    </Slider>
                </div>
            </section>
        )
    }
}

export default ServicesSlider;