import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';

import img1 from "../../../img/bsuwb/001.jpeg"
import img2 from "../../../img/bsuwb/002.jpeg"
import img3 from "../../../img/bsuwb/003.jpeg"
import img4 from "../../../img/bsuwb/004.jpeg"
import img5 from "../../../img/bsuwb/005.jpeg"
import img6 from "../../../img/bsuwb/006.jpeg"
import img7 from "../../../img/bsuwb/007.jpeg"
import img8 from "../../../img/bsuwb/008.jpeg"
import img9 from "../../../img/bsuwb/009.jpeg"
import img10 from "../../../img/bsuwb/010.jpeg"
import img11 from "../../../img/bsuwb/011.jpeg"
import img12 from "../../../img/bsuwb/012.jpeg"

const items = [
    {
        src:img1,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img2,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img3,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img4,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img5,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img6,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img7,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img8,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img9,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img10,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        src:img11,
        altText: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर',
        caption: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },

];

const Example = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.src}
            >
                <img src={item.src} className="d-block w-100" alt={item.altText} />
            </CarouselItem>
        );
    });

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            ride='carousel'
            interval={4000}
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
}

export default Example;