import React from 'react';
import {Layout} from "./partials/Layout";
import Table from "./partials/Table";


const titles=[
    {
        label:'वर्ष',
        key:'1'
    },
    {
        label:'लक्ष्य नि.',
        key:'2'
    },
    {
        label:'पूर्ति नि.',
        key:'3'
    },
    {
        label:'लक्ष्य अनि.',
        key:'4'
    },
    {
        label:'पूर्ति अनि.',
        key:'5'
    },
    {
        label:'लक्ष्य नि. व अनि.',
        key:'6'
    },
    {
        label:'पूर्ति योग नि. व अनि.',
        key:'7'
    },
    {
        label:'पूर्ति प्रतिशत',
        key:'8'
    },
    {
        label:'वर्ष में लाभ हानि',
        key:'9'
    },
]

const data=[
    {
        1:'2017-18',
        2:'0',
        3:'0',
        4:'2725',
        5:'2088,2',
        6:'2725',
        7:'2088,20',
        8:'76,63',
        9:'-31,44',
    },
    {
        1:'2018-19',
        2:'0',
        3:'0',
        4:'2556',
        5:'2137,73',
        6:'2556',
        7:'2137,73',
        8:'83,64',
        9:'7,42'
    },
    {
        1:'2019-20',
        2:'0',
        3:'0',
        4:'2680',
        5:'2054,5',
        6:'2680',
        7:'2054,50',
        8:'76,66',
        9:'5,09'
    },
    {
        1:'2020-21',
        2:'0',
        3:'0',
        4:'2714',
        5:'1648,73',
        6:'2714',
        7:'1648,73',
        8:'60,75',
        9:'-'
    },
    {
        1:'2021-22',
        2:'0',
        3:'0',
        4:'0',
        5:'234,19',
        6:'0',
        7:'234,19',
        8:'0',
        9:'-'
    },

]


function OurTurnover(props) {
    return (
        <Layout>
            <Table data={data} heading={'व्यापारिक प्रगति रिपोर्ट'} titles={titles}/>
        </Layout>
    );
}

export default OurTurnover;