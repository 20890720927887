import React from 'react';
import Table from "./partials/Table";
import {Layout} from "./partials/Layout";


const titles = [
    {
        label: 'क्र.',
        key: 'index'
    },
    {
        label: 'नाम सदस्य',
        key: 'name'
    },
    {
        label: 'पद',
        key: 'designation'
    },
    {
        label: 'पता',
        key: 'address'
    },
    {
        label: 'टेलीफोन नम्बर',
        key: 'mobile'
    },
]

const data = [
    {
        name: 'श्री नागेन्द्रपाल सिंह',
        designation: 'अध्यक्ष',
        address: '',
        mobile: '6375137289'
    },
    {
        name: 'श्री विनोद कुमार चौबदार',
        designation: 'उपाध्यक्ष',
        address: '',
        mobile: '9414332129'
    },
    {
        name: 'श्री सुरेन्द्र सिंह राठौड़',
        designation: 'संचालक',
        address: '',
        mobile: '9928327274'
    },
    {
        name: 'श्री चैन सिंह',
        designation: 'संचालक',
        address: '',
        mobile: ''
    },
    {
        name: 'श्री हेतराम',
        designation: 'संचालक',
        address: '',
        mobile: '9929842524'
    },
    {
        name: 'श्रीमती शिल्पा खीचड़',
        designation: 'संचालक',
        address: '',
        mobile: '9166625904'
    },
    {
        name: 'श्री धर्मवीर सिंह',
        designation: 'संचालक',
        address: '',
        mobile: '9828798143'
    },
    {
        name: 'श्री दिलीप कुमार सेवग',
        designation: 'संचालक',
        address: '',
        mobile: '9610503490'
    },
    {
        name: 'श्रीमती बबीता शर्मा',
        designation: 'संचालक',
        address: '',
        mobile: '9079632130'
    },
    {
        name: 'श्रीमती कुसुम शर्मा',
        designation: 'संचालक',
        address: '',
        mobile: '9414429028'
    },
    {
        name: 'श्री राहुल',
        designation: 'संचालक',
        address: '',
        mobile: '7300382349'
    },
    {
        name: 'श्री खेम सिंह',
        designation: 'संचालक',
        address: '',
        mobile: '7597743214'
    }
]


function BoardOfDirectors(props) {
    return (
        <Layout>
            <Table data={data} heading={'Board of Directors'} titles={titles}/>
        </Layout>
    );
}

export default BoardOfDirectors;