import React from 'react';
import CustomNavbar from '../../../components/CustomNavbar';
import FooterData from '../../../components/Footer/FooterData';
import Footer from './Footer'
import Carousel from "./Carousal2";
import Advert from "./Advert";
import ServicesSlider from "./ServicesSlider";
import News from "./News";
// import vedio from "../../../vdo1.mp4";

export const Layout = (props) => (
    <div className="body_wrapper">
        <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
        <div className="row" style={{marginTop:100}}>
            <div className="col-lg-5 m-0 p-0">
                <Carousel/>
                <Advert/>
                <News/>
                {/* <video width="100%" height="300" controls>
                    <source src={vedio} type="video/mp4"/>
                        Your browser does not support the video tag.
                </video> */}
            </div>
            <div className="col-lg-7 p-5" style={{
                // background:'#f8f9fd' ,
                backgroundColor:'#f9e1c1'
            }}>
                {props.children}
            </div>
        </div>
        <ServicesSlider/>
        <Footer FooterData={FooterData}/>
    </div>
)