import React from "react";
import {Layout} from "./partials/Layout";
const NotFound = () => (
    <Layout>
        {/*<section className="error_two_area">*/}
            <div className="container flex mt-5">
                <div className="error_content_two text-center">
                    <img src={require("../../img/new/error.png")} alt="" />
                    <h2>Error. We can’t find the page you’re looking for.</h2>
                    <p>
                        Sorry for the inconvenience. Go to our homepage.
                    </p>
                    <a href="/" className="about_btn btn_hover">
                        Back to Home Page <i className="arrow_right"></i>
                    </a>
                </div>
            </div>
        {/*</section>*/}
    </Layout>

);
export default NotFound;
