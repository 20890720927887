import React from 'react';
import Table from "./partials/Table";
import {Layout} from "./partials/Layout";


const titles = [
    {
        label: '',
        key: 'index'
    },
    {
        label: '',
        key: '1'
    },
    {
        label: '',
        key: '2'
    },
]

const sellers = [
    {
        name: 'श्री चैन सिंह',
        post: 'गैस गोदाम प्रभारी'
    },
    {
        name: 'श्री राजेन्द्र सिंह',
        post: 'प्रभारी गैस शाखा'
    },
    {
        name: 'श्री भवानी सिंह',
        post: 'गैस मैकेनिक का कार्य'
    },
    {
        name: 'श्री आनन्द शर्मा',
        post: 'मेडिकल विक्रेता शोप नं. 20 पीबीएम कैम्पस, पेंशनर्स तथा आयुर्वेदिक शोप'
    },
    {
        name: 'श्री अजय गुप्ता',
        post: 'मेडिकल विक्रेता शोप नं. 14 पीबीएम कैम्पस, पेंशनर्स शोप'
    },
    {
        name: 'श्री राजेन्द्र औझा',
        post: 'मेडिकल स्टोरकीपर प्रभारी '
    },
    {
        name: 'श्री अनिल गुप्ता',
        post: 'मेडिकल विक्रेता शोप नं. 6 पीबीएम कैम्पस,'
    },
    {
        name: 'श्री हरिकिशन शर्मा',
        post: 'मेडिकल विक्रेता शोप नं. 7 पीबीएम कैम्पस,'
    },
    {
        name: 'श्री मुकेश श्रीमाली',
        post: 'मेडिकल विक्रेता शोप नं. 11 सेटेलाईट'
    },
    {
        name: 'श्री वसीम अकरम',
        post: 'मेडिकल विक्रेता शोप नं. 9 अणचा बाई'
    },
    {
        name: 'श्री शाहजार मिर्जा',
        post: 'मेडिकल विक्रेता शोप नं. 17 नोखा'
    },
    {
        name: 'श्री पवन शर्मा',
        post: 'विक्रेता उपहार सुपर मार्केट जेएनवी काॅलोनी'
    },
    {
        name: 'श्री दलपत सिंह',
        post: 'विक्रेता उपहार सुपर मार्केट केईएम रोड़'
    },
    {
        name: 'श्री पृथ्वी सिंह',
        post: 'विक्रेता रिटेल कपड़ा शोप केईएम रोड़'
    },
]


const data = [
    {
        1: 'नाम संस्था',
        2: 'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        1: 'पता',
        2: 'विजय सहकार भवन, श्री गंगानगर रोड़, बीकानेर'
    },
    {
        1: 'पंजीयन संख्या',
        2: '373/एफ दिनांक 23.1.1963'
    },
    {
        1: 'कार्य क्षेत्र',
        2: 'बीकानेर जिला'
    },
    {
        index:(<div  style={{
            paddingTop:'15px'
        }}>5</div>),
        1: <div style={{
            paddingTop:'15px'
        }}>'संस्था द्वारा संचालित विक्रय केन्द्र'</div>,
        2: (<table>
            {sellers.map((seller,index)=>(
                <tr>
                    <td>{index+1}</td>
                    <td>{seller.name}</td>
                    <td>{seller.post}</td>
                </tr>
            ))}
        </table>),
        verticalAlign:'top'
    },

]


function OurShops(props) {
    return (
        <Layout>

        <Table header={false} titles={titles} heading={'Our Shops'} data={data}/>
        </Layout>

    );
}

export default OurShops;