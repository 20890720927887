import React from 'react';
import {Card, CardBody, CardImg, CardTitle} from "reactstrap";
import IndaneImg from "../../img/bsuwb/indane_img.png"
import ClothImg from "../../img/bsuwb/cloth.jpg"
import MedicineImg from "../../img/bsuwb/medicine_crop.jpg"
import SuperMarketImg from "../../img/bsuwb/supermarket_crop.jpg"
import OldAgeImg from "../../img/bsuwb/old_age_crop.jpg"
import {Layout} from "./partials/Layout";


const works=[
    {
        name:'मेडिसिन का व्यापार',
        img:MedicineImg,
    },
    {
        name:'जनरल खाद्यय सामग्री का व्यापार',
        img:SuperMarketImg,
    },
    {
        name:'कपड़े का व्यापार',
        img:ClothImg,
    },
    {
        name:'गैस सिलेण्डर सप्लाई का कार्य',
        img:IndaneImg,
    },
    {
        name:'पेंशनर्स को जैनरिक दवाईयाॅॅ उपलब्ध करवाना',
        img:OldAgeImg,
    }

]

function OurWork(props) {
    return (
        <Layout>
            <div className="text-center">
                <div className="mb-4 d-inline-block py-2 px-3" style={{
                    backgroundColor:'#ff6633',
                    color:'white'
                }}>
                    <h4 className={"m-0 p-0"} style={{
                        color:'white'
                    }}>Our Work</h4>
                </div>
            </div>
            <div className={'row'}>
                {works.map(work=>(
                    <div className="col-md-6 col-xl-4 p-4">
                        <Card>
                            <CardImg top width="100%" src={work.img} alt="Card image cap" />
                            <CardBody className={'p-1'}>
                                <div className="d-flex w-100 justify-content-center align-items-center" style={{minHeight:'5rem'}}>
                                <CardTitle className={'text-center m-0'} tag="h5">{work.name}</CardTitle>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                ))}

                {/*<div className="col-md-4">*/}
                {/*    <Card>*/}
                {/*        <CardImg top width="100%" src={IndaneImg} alt="Card image cap" />*/}
                {/*        <CardBody>*/}
                {/*            <CardTitle className={'text-center'}  tag="h5">जनरल खाद्यय सामग्री का व्यापार</CardTitle>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div className="col-md-4">*/}
                {/*    <Card>*/}
                {/*        <CardImg top width="100%" src={IndaneImg} alt="Card image cap" />*/}
                {/*        <CardBody>*/}
                {/*            <CardTitle className={'text-center'}  tag="h5">कपड़े का व्यापार</CardTitle>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div className="col-md-4">*/}
                {/*    <Card>*/}
                {/*        <CardImg top width="100%" src={IndaneImg} alt="Card image cap" />*/}
                {/*        <CardBody>*/}
                {/*            <CardTitle className={'text-center'}  tag="h5">गैस सिलेण्डर सप्लाई का कार्य</CardTitle>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</div>*/}
                {/*<div className="col-md-4">*/}
                {/*    <Card>*/}
                {/*        <CardImg top width="100%" src={IndaneImg} alt="Card image cap" />*/}
                {/*        <CardBody>*/}
                {/*            <CardTitle className={'text-center'}  tag="h5">पेंशनर्स को जैनरिक दवाईयाॅॅ उपलब्ध करवाना</CardTitle>*/}
                {/*        </CardBody>*/}
                {/*    </Card>*/}
                {/*</div>*/}
            </div>
        </Layout>

    );
}

export default OurWork;