import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed" >
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container-fluid ${cClass}`}>
                        <Link className={`navbar-brand px-4 py-3 ${slogo}`} to="/" style={{
                            backgroundColor:'#ff6633',
                            borderRadius:15
                        }}>
                            <img src={require("../img/bsuwb/bsuwb_logo.png")} width={100} alt=""/>
                            <img src={require("../img/bsuwb/bsuwb_logo.png")} width={100}  alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto mr-5 ${nClass}`}>
                                <li className="nav-item"><NavLink title="Home" className="nav-link" to="/">Home</NavLink></li>
                                <li className="nav-item"><NavLink title="Contact Us" className="nav-link" to="/contact-us">Contact Us</NavLink></li>
                                <li className="nav-item"><NavLink title="About Us" className="nav-link" to="/about-us">About Us</NavLink></li>
                                <li className="nav-item"><NavLink title="Our Location" className="nav-link" to="/our-location">Our Location</NavLink></li>
                                <li className="nav-item"><NavLink title="Our Shops" className="nav-link" to="/our-shops">Our Shops</NavLink></li>
                                {/* <li className="nav-item"><NavLink title="Our Turnover" className="nav-link" to="/our-turnover">Our Turnover</NavLink></li> */}
                                <li className="nav-item"><NavLink title="Our Work" className="nav-link" to="/our-work">Our Work</NavLink></li>
                                <li className="nav-item"><NavLink title="Board of Directors" className="nav-link" to="/board-of-directors">Board of Directors</NavLink></li>
                                <li className="nav-item"><NavLink title="Our Office" className="nav-link" to="/our-office">Our Office</NavLink></li>
                            </ul>
                            {/*<a className={`btn_get btn_hover ${hbtnClass}`} href="#get-app">Get Started</a>*/}
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;