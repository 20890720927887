import React from 'react';
import {Layout} from "./partials/Layout";
import Table from "./partials/Table";



const titles=[
    {
        label:'क्र.',
        key:'index'
    },
    {
        label:'सुविधाएं हेतु संपर्क करे',
        key:'name'
    },    
]

const data=[
    {
        name:'भंडार के सुपर मार्केट केईएम रोड व जेएनवी कॉलोनी से 2000 /- रु या अधिक की खरीद पर होम डिलीवरी की सुविधा फ्री'        
    },
    {
        name:'होम डिलीवरी आर्डर के लिए संपर्क मो: 9252570777 व 8559963035'        
    },
    {
        name:(
            <table className={'w-100'}>
                <tr>
                    <td>गैस बुकिंग के लिए मो न:</td>                    
                </tr>
                <tr>
                    <td>    मिस कॉल न: 8454955555</td>
                </tr>
                <tr>
                    <td>    व्हाट्सप न: 7588888824</td>
                </tr>
                <tr>
                <td>    आईवीआरएस न: 7718955555</td>
                </tr>
                <tr>
                <td>    लीकेज शियाकत न: 1906</td>
                </tr>
            </table>
           ),
        verticalAlign:'top'
    },
    {
        name:'गैस मैकेनिक के सम्पर्क न: 9413207193 '
    },
    {
        name:'गैस कनेक्शन लेने हेतु सम्पर्क न: 9660009294 '
    },
    {
        name:'कपडे की खरीद हेतु सम्पर्क न: 9460779517 '
    },
    

]



function ContactUs(props) {
    return (
        <Layout>
            <Table data={data} heading={'Contact Us'} titles={titles}/>
        </Layout>
    );
}

export default ContactUs;