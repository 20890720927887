import React from 'react';
import {Layout} from "./partials/Layout";
import Table from "./partials/Table";


const titles=[
    {
        label:'',
        key:'index'
    },
    {
        label:'',
        key:'1'
    },
    {
        label:'',
        key:'2'
    },
]

const data =[
    {
        1:'नाम संस्था',
        2:'बीकानेर सहकारी उपभोक्ता होलसेल भण्डार लि. बीकानेर'
    },
    {
        1:'पता',
        2:'विजय सहकार भवन, श्री गंगानगर रोड़, बीकानेर'
    },
    {
        1:'पंजीयन संख्या',
        2:'373/एफ दिनांक 23.1.1963'
    },
    {
        1:'कार्य क्षेत्र',
        2:'बीकानेर जिला'
    },
    {
        1:'संस्था द्वारा संचालित विक्रय केन्द्र',
        2:(<div className="row">
            <div className="col-10">उपहार सुपर मार्केट/सुपर मार्केट</div>
            <div className="col-2">2</div>
            <div className="col-10">रिटेल कपड़ा शोप</div>
            <div className="col-2">1</div>
            <div className="col-10">मेडिकल शोप/आयुर्वेदिक शोप</div>
            <div className="col-2">7</div>
            <div className="col-10">गैस शोरुम (इंडेन गैस एजेन्सी)</div>
            <div className="col-2">1</div>
        </div> ),
        verticalAlign:'top'

    },
]

function OurOffice(props) {
    return (
        <Layout>
          <Table header={false} titles={titles} heading={'Our Office'} caption={'Email ID - gmbhandarbkn63@yahoo.com'} data={data}/>
        </Layout>
    );
}

export default OurOffice;